import React, { useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";

// Sections for this page
import GameSection from "./Sections/GameSection.js";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);


export default function GamePage(props) {
  const classes = useStyles();
  const [banner, setBanner] = useState('code');
  const [title, setTitle] = useState('Games by StretchMasterP');
  const [desc, setDesc] = useState('Check out my games');
  const { ...rest } = props;

  function SetBanner(data){
    setBanner(data.category);
    setTitle(data.title);
    setDesc(data.description);
  }


  return (
    <div>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand="PyeDev.co.uk"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
        {...rest}
      />
      <Parallax filter image={require("assets/img/games-bg.jpg")}>
        <div className={classes.container}>
        
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <h1 className={classes.title}>{title}</h1>
              <h4>
                {desc}
              </h4>
       
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>      
        <GameSection onBannerChange={SetBanner}/>
        </div>
      </div>
      <Footer />
    </div>
  );
}
